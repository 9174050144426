<template>
	<div
		:id="notification.id.toString()"
		class="sticky top-40 grid w-80 max-w-xs cursor-pointer grid-cols-4 flex-row items-center gap-3 rounded bg-white shadow-md"
	>
		<IconsClose
			class="absolute right-1 top-1 z-50 h-3 w-3 cursor-pointer"
			@click="emit('close')"
		/>

		<!-- Icons -->
		<div
			class="flex h-full items-center justify-center rounded-bl rounded-tl"
			:class="notificationTypeClass"
		>
			<ExclamationTriangleIcon v-if="type === 'error'" class="h-10 w-10 stroke-white" />
			<InformationCircleIcon v-if="type === 'info'" class="h-10 w-10 stroke-white" />
			<CheckCircleIcon v-if="type === 'success'" class="h-10 w-10 stroke-white" />
			<ExclamationCircleIcon v-if="type === 'warning'" class="h-10 w-10 stroke-white" />
		</div>

		<!-- Text -->
		<div class="col-span-3 px-1 py-2">
			<p class="font-bold capitalize">{{ notification.type }}</p>
			<p class="text-sm">{{ notification.message }}</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import IconsClose from '../icons/Close.vue';
import ExclamationTriangleIcon from '@heroicons/vue/24/outline/ExclamationTriangleIcon';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/ExclamationCircleIcon';
import InformationCircleIcon from '@heroicons/vue/24/outline/InformationCircleIcon';
import CheckCircleIcon from '@heroicons/vue/24/outline/CheckCircleIcon';
import { computed } from 'vue';
import type { Notification } from '~/types/app.types';

const props = defineProps<{
	notification: Notification;
}>();

const type = computed(() => props.notification.type.toLowerCase());

const emit = defineEmits(['close']);

const notificationTypeClass = computed(() => {
	switch (type.value) {
		case 'info':
			return 'bg-neutral-400';
		case 'success':
			return 'bg-cta';
		case 'warning':
			return 'bg-yellow-500';
		case 'error':
			return 'bg-[#c10303]';
		default:
			return 'bg-black';
	}
});
</script>
