<template>
	<TransitionGroup
		tag="ul"
		enter-active-class="transition duration-300"
		leave-active-class="transition duration-300"
		enter-from-class="opacity-50 translate-x-[200px]"
		leave-to-class="opacity-50 translate-x-[200px]"
		class="fixed right-5 top-48 z-[100] float-right mt-5 flex h-fit w-fit flex-col gap-2"
	>
		<Notification
			v-for="notification in NotificationStore.state.notifications"
			:key="notification.id"
			:notification="notification"
			@close="NotificationStore.removeNotification(notification.id)"
		/>
	</TransitionGroup>
</template>

<script setup lang="ts">
import Notification from './Notification.vue';
import { useNotificationStore } from '../../stores/notificationStore';

const NotificationStore = useNotificationStore();
</script>
